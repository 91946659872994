import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { header } from './header.module.scss'
import { IoReorderTwoOutline, IoCloseOutline } from 'react-icons/io5'

const navigation = [
  { name: 'Projects', href: '/' },
  { name: 'Contact', href: '/office/#contact' },
  { name: 'About', href: '/office/#about' },
  { name: 'People', href: '/office/#people' },
]

const Header = ({ siteTitle, isVisible = true }) => (
  <header id="header" className={`z-50 fixed w-full`}>
    <Disclosure
      as="nav"
      className={`bg-white fixed w-full ${header} transition duration-500 ${
        isVisible ? 'translate-y-0' : 'delay-300 -translate-y-full'
      }`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto pl-4 pr-2 sm:px-4">
            <div className="relative flex items-center justify-between h-12 md:h-16">
              <div className={`flex-1 flex items-center justify-center`}>
                <div className="flex-shrink-0">
                  <Link
                    to="/"
                    className="font-display font-bold text-lg md:text-xl hover:text-black"
                    activeClassName="active"
                  >
                    TOUSAS
                    {/*{siteTitle}*/}
                  </Link>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center z-50">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  className="inline-flex items-center justify-center p-2 hover:text-black hover:bg-gray-100 focus:outline-none"
                  style={{ fontSize: '2em' }}
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? <IoCloseOutline /> : <IoReorderTwoOutline />}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Transition>
            <Transition.Child
              enter="transform origin-right ease-out"
              enterFrom="opacity-0 translate-x-1/2"
              enterTo="opacity-100 translate-x-0"
              leave="transform origin-right ease-out"
              leaveFrom="opacity-100 -translate-x-0"
              leaveTo="opacity-0 translate-x-1/2"
              className={`absolute w-screen md:w-2/5 lg:w-1/4 max-w-md h-screen top-0 right-0 bg-white/95 transform transition duration-200 ${
                isVisible ? 'opacity-100' : 'opacity-0'
              }`}
            >
              &nbsp;
            </Transition.Child>
            <Transition.Child
              enter="transition duration-300 ease-out"
              enterFrom="transform translate-x-1/4 opacity-0"
              enterTo="transform translate-x-0 opacity-100"
              leave="transition duration-100 ease-out"
              leaveFrom="transform translate-x-0 opacity-100"
              leaveTo="transform translate-x-1/4 opacity-0"
              className={`absolute w-screen md:w-2/5 lg:w-1/4 max-w-md h-screen top-0 right-0 px-2 pt-2 pb-3 space-y-1 transition duration-200 ${
                isVisible ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <Disclosure.Panel className="w-full h-full">
                <Disclosure.Button
                  className={`absolute w-full h-full top-0 left-0 panel-bg cursor-default`}
                >
                  {''}
                </Disclosure.Button>
                <div className="flex flex-col justify-center md:justify-start h-full text-gray-500 md:pr-2 lg:pr-12 md:pt-28">
                  {navigation.map((item) => (
                    <Disclosure.Button key={item.name}>
                      <Link
                        to={item.href}
                        className="relative block text-center px-3 py-2 font-display font-bold tracking-widest text-lg md:text-xl hover:text-black uppercase md:text-right"
                        activeClassName="active text-black cursor-default hover:text-black"
                      >
                        {item.name}
                      </Link>
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </Transition.Child>
          </Transition>
        </>
      )}
    </Disclosure>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
