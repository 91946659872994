/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import '@fontsource/brygada-1918/400.css'
import '@fontsource/brygada-1918/600.css'

import Header from './header'

const Layout = ({ children, classes, pageTitle = '' }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [scrollPos, setScrollPos] = useState(0)
  const [scrollDirection, setScrollDirection] = useState(0)
  const [headerVisible, setHeaderVisible] = useState(true)
  let ticking = false
  const politeScroll = (newScrollPosition) => {
    setScrollDirection(scrollPos > newScrollPosition ? -1 : 1)
    setScrollPos(newScrollPosition)
    setHeaderVisible(scrollDirection !== 1)
  }
  const scrollHandler = () => {
    if (!ticking) {
      window.requestAnimationFrame(function () {
        politeScroll(window.scrollY)
        ticking = false
      })
      ticking = true
    }
  }

  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      document.addEventListener('scroll', scrollHandler)
    }
    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  })

  return (
    <div className="layout">
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        isVisible={headerVisible}
      />
      <div className={`content ${classes}`}>
        <main>{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
